.default-body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:
    /* top, transparent red, faked with gradient */
    linear-gradient( rgba(27, 41, 80, 0.05), rgba(27, 41, 80, 0.08) ),
        
    url(../../static/media/watermark.21c2d7a1.svg) no-repeat fixed 90% 0;
}

.wizard-body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(270deg, #35E0F4 0.06%, #33D7F0 7.01%, #2EBDE4 18.59%, #2594D0 33.34%, #195BB6 50.53%, #1132A3 61.66%, #1132A3 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.icon-button {
    cursor: pointer;
}

.ui.sidebar.sidebar-menu {
    z-index: 2000;
}

    .ui.sidebar.sidebar-menu .logout {
        padding-top: 10px;
        padding-bottom: 60px;
    }

.imgLogoHeader img {
}


.styleLanguageFooter {
    float: right;
    margin-top: -6px;
}

.styleVersionFooter {
    float: left;
    margin-top: 2px;
}

ul#org {
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.labelUpShadow {
    display: inline-block;
    position: absolute;
    left: 90px;
    bottom: 42px;
    transition: all 150ms ease-in;
    color: #676767;
    z-index: 9999;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

@media only screen and (max-width: 767px) {
    .sizeContainer {
        width: auto !important;
        margin-left: 1em !important;
        margin-right: 1em !important
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    .sizeContainer {
        width: 774px;
        margin-left: auto !important;
        margin-right: auto !important
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .sizeContainer {
        width: 984px;
        margin-left: auto !important;
        margin-right: auto !important
    }
}

@media only screen and (min-width: 1200px) {
    .sizeContainer {
        width: 1178px;
        margin-left: auto !important;
        margin-right: auto !important
    }
}

@media only screen and (max-width: 767px) {
    .sizeContainer {
        width: auto !important;
        margin-left: 1em !important;
        margin-right: 1em !important
    }
}

@media only screen and (min-width: 768px) and (max-width:991px) {
    .sizeTitleHeader {
        margin-left: -76px !important
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .sizeTitleHeader {
        margin-left: -76px !important
    }
}

@media only screen and (min-width: 1200px) {
    .sizeTitleHeader {
        margin-left: -76px !important
    }
}

.ui.primary.button {
    background-color: #fa4261;
}

.color-letter-dropdown-white > div {
    color: white !important;
}

.cashio-detail-table {
    border-collapse: collapse;
    width: 100%;
}

.cashio-detail-table td, .cashio-detail-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.cashio-detail-table tr:nth-child(even) {
    background-color: #dddddd;
}

.ui.search.member-search input {
    border-radius: 0.28571429rem;
}

.wizard-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 !important;
    letter-spacing: -0.02em;
}

.wizard-container2 {
    position: absolute;
    left: 32.5%;
    top: 15%;
    background: white;
    padding: 0 !important;
    letter-spacing: -0.02em;
}

.wizard-btn {
    padding: 10px 30px !important;
    background: transparent !important;
    border-radius: 100px !important;
    color: white !important;
    text-transform: inherit !important;
    width: 230px;
}

.wizard-btn.primary {
    background: linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%) !important;
}

.wizard-btn.secondary {
    color: #16359D !important;
    border: 3px solid #16359D !important;
}

.wizard-btn:disabled {
    background: #E4E4E4 !important;
    color: #C7C7C7 !important;
}

.terms-and-conditions {
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
}

.wizard-box {
    padding: 50px;
}

.ml-20 {
    margin-left: 20px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.icon-color {
    color: #16359D !important;
}

.info-text {
    line-height: 26px !important;
}

.link-text {
    color: #16359D;
    text-decoration: underline;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.font-bold {
    font-weight: bold !important;
}

.primary-color {
    color: #16359D;
}

.MuiInputLabel-asterisk {
    color: red;
}

.terms-and-conditions p {
    margin-bottom: 20px;
}

.terms-and-conditions a {
    font-weight: bold;
    color: #1132a3;
    text-decoration: underline;
}

.org-detail-file-link {
    color: #4183c4;
    cursor: pointer;
}

.partner-feature {
    margin-left: 50px;
    display: inline-block;
    border: 2px solid gray;
    padding: 25px;
    border-radius: 10px;
}
.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.headerDropdown .ui.button.floating.labeled.dropdown {
    color: white;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.text-danger {
    color: #FA4261;
}

.text-info {
    color: #1B2950;
}

.text-success {
    color: #589352;
}

.text-warning {
    color: #FBCCA1;
}

.ui.modal .ui.loader:before {
    border: .2em solid rgba(0,0,0,.1) !important;
}

.title-transfer-kinebank {
    margin: 1em 0px 2em;
    background-color: #c3c3c3;
    padding: 18px !important;
    border-radius: 9px;
}

.member-details-kyc-clear {
    position: absolute;
    right: 1.5rem;
    top: 0.85rem;
    margin-right: 20% !important;
    background-color: #fa4261 !important;
    color: white !important;
}

.code-generate-date-range .field {
    display: inline;
}

.error .ui.input > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none;
}

.ref-add-label {
    width: 154px !important;
}

.ref-update-label {
    width: 120px !important;
}
