.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.headerDropdown .ui.button.floating.labeled.dropdown {
    color: white;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.text-danger {
    color: #FA4261;
}

.text-info {
    color: #1B2950;
}

.text-success {
    color: #589352;
}

.text-warning {
    color: #FBCCA1;
}

.ui.modal .ui.loader:before {
    border: .2em solid rgba(0,0,0,.1) !important;
}

.title-transfer-kinebank {
    margin: 1em 0px 2em;
    background-color: #c3c3c3;
    padding: 18px !important;
    border-radius: 9px;
}

.member-details-kyc-clear {
    position: absolute;
    right: 1.5rem;
    top: 0.85rem;
    margin-right: 20% !important;
    background-color: #fa4261 !important;
    color: white !important;
}

.code-generate-date-range .field {
    display: inline;
}

.error .ui.input > input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ref-add-label {
    width: 154px !important;
}

.ref-update-label {
    width: 120px !important;
}